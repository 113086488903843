;"use strict";

var NS = {
    header: function(){},
    main: function (){},
    footer: function () {},
    check_to_top: function(){
        if(jQuery(document).scrollTop() >= 100){
            jQuery("#to_top").show(400);
        }else{
            jQuery("#to_top").hide();
        }
    },
    to_top: function(){
        if(jQuery("a[href='#top']")){
            jQuery("a[href='#top']").on("click", function() {
                jQuery("html, body").animate({ scrollTop: 0 }, "slow");
                return false;
            });
        }
    },
    close: function () {
        var $c_i_c_c = $(".js_close_info_cart_copy");
        if($c_i_c_c){
            $c_i_c_c.on("click", function () {
                $(this).closest('.info_cart_copy').slideToggle();
                return false;
            });
        }
    },
    accordion: function () {
        var $accordion = $("#accordion");
        if($accordion){
            $accordion.find(".panel-heading").each(function (i, elem) {
                $(this).on("click", function () {
                    if($(this).hasClass("active")){
                        $(this).removeClass("active");
                        $(this).next().removeClass("in");
                        $(this).next().hide(400);
                    }else{
                        $(this).addClass("active");
                        $(this).next().addClass("in");
                        $(this).next().show(400);
                    }
                });
            });
        }
        /*$("a[href^='#']").on('click', function(e){
            e.preventDefault();
            var full_url = $(this).attr('href');
            $('html, body').animate({scrollTop: $(full_url).offset().top}, 1500);
        });*/
    },
    slide_menu: function () {
        if($("#slide_menu").length){
            var $hcm = $('.js_mobile_btn'),
                $master = $('.wrapper'),
                $slideMenu = $('#slide_menu'),
                $nano = $('.nano');
            if ($nano.length) {
                $hcm.on('click', function () {
                    $hcm.toggleClass("active");
                    $master.toggleClass("slided");
                    $slideMenu.toggleClass("slided");
                    return false;
                });
                $(".slideMenuClose").on('click', function () {
                    $hcm.removeClass('active');
                    $master.removeClass('slided');
                    $slideMenu.removeClass('slided');
                });
                $nano.nanoScroller({
                    preventPageScrolling: true,
                    alwaysVisible: true,
                    iOSNativeScrolling: true
                });
            }
        }
    },
    map: function () {
        if(document.getElementById("map")){
            var directionsDisplay,
                directionsService,
                map;

            directionsService = new google.maps.DirectionsService();
            directionsDisplay = new google.maps.DirectionsRenderer();
            var place = new google.maps.LatLng(51.513741, -0.179098);
            var mapOptions = {
                zoom: 8,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: place
            };
            map = new google.maps.Map(document.getElementById("map"), mapOptions);
            directionsDisplay.setMap(map);
            var marker = new google.maps.Marker({
                position: place,
                map: map,
                icon: 'img/map-point.png'
            });
        }
    },
    map2: function(){
        if(document.getElementById("map2")){
            var directionsDisplay,
                directionsService,
                map2;

            directionsService = new google.maps.DirectionsService();
            directionsDisplay = new google.maps.DirectionsRenderer();
            var place = new google.maps.LatLng(51.513741, -0.179098);
            var place2 = new google.maps.LatLng(51.513741, -0.178098);
            var mapOptions = {
                zoom: 18,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: place
            };
            if($(document).width() <= 767){
                mapOptions.center = place2;
            }

            map2 = new google.maps.Map(document.getElementById("map2"), mapOptions);
            directionsDisplay.setMap(map2);

            var marker = new google.maps.Marker({
                position: place2,
                map: map2,
                icon: 'img/map-point.png'
            });
        }
    },
    init: function () {
        this.header();
        this.main();
        this.footer();
        this.check_to_top();
        this.to_top();
        this.close();
        this.map();
        this.map2();
        this.slide_menu();
    }
};

$(window).on("load", function () {

    NS.init();

    var $owl = $(".owl-carousel"),
        $arrow = "<?xml version='1.0' encoding='utf-8'?><svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 129 129' xmlns:xlink='http://www.w3.org/1999/xlink' enable-background='new 0 0 129 129'><g><path fill='#d4e3ec' d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z'/></g></svg>";

    var $owl_s = $(".similar_products .owl-carousel");
    if($owl_s){
        $owl_s.owlCarousel({
            responsive:{
                margin: 15,
                nav: true,
                navText: [$arrow,$arrow],
                0:{
                    items: 1,
                    loop: true,
                    margin: 0,
                    nav: true,
                    navText: [$arrow,$arrow]
                },
                768:{
                    items: 3,
                    loop: true,
                    margin: 15,
                    nav: true,
                    navText: [$arrow,$arrow]
                },
                1170:{
                    items: 5,
                    margin: 15,
                    loop: true,
                    nav: true,
                    navText: [$arrow,$arrow]
                }
            }
        });
    }

    if($owl){
        $owl.owlCarousel({
            responsive:{
                margin: 0,
                nav: true,
                navText: [$arrow,$arrow],
                0:{
                    items: 1,
                    loop: true,
                    margin: 0,
                    nav: true,
                    navText: [$arrow,$arrow]
                },
                768:{
                    items: 3,
                    loop: true,
                    margin: 0,
                    nav: true,
                    navText: [$arrow,$arrow]
                },
                1170:{
                    items: 5,
                    margin: 0,
                    loop: true,
                    nav: true,
                    navText: [$arrow,$arrow]
                }
            }
        });
    }

    ////////////////////////


    var $login_item = $(".panel-item.login"),
        $js_basket = $(".js_basket"),
        $cart_drop_wrap = $("#cart_drop_wrap");
    if($login_item){
        $login_item.on("click", function () {
            $js_basket.removeClass("active");
            $cart_drop_wrap.removeClass("opened");
            $(this).toggleClass("opened");
            return false;
        });
    }
    $(document).not($login_item).on("click", function (e) {
        $login_item.removeClass("opened");
    });
    $(".login_form_wrap").on("click", function (e) {
        e.stopPropagation();
    });
    if($js_basket && $cart_drop_wrap){
        $js_basket.on("click", function () {
            $(this).toggleClass("active");
            $cart_drop_wrap.toggleClass("opened");
            return false;
        });
    }
    $(document).not($js_basket).on("click", function (e) {
        $js_basket.removeClass("active");
        $cart_drop_wrap.removeClass("opened");
    });
    $cart_drop_wrap.on("click", function (e) {
        e.stopPropagation();
    });

    ////////////



    $(".js_select2").select2({
        minimumResultsForSearch: -1
    });

    //$(".nano").nanoScroller();
    //jQuery('.scrollbar-macosx').scrollbar();


    var $b_remove = $("button.remove"),
        $b_add = $("button.add"),
        $input_number = $b_remove.find("+ input[type=number]");

    $input_number.attr("min", 0);
    $input_number.attr("value", 0);
    if($b_remove && $b_add){
        $b_remove.attr("onclick", "this.parentNode.querySelector('input[type=number]').stepDown()");
        $b_add.attr("onclick", "this.parentNode.querySelector('input[type=number]').stepUp()");
    }
    var $icon_count = $(".icon_count");
    setTimeout(function () {
        if($icon_count){
            $icon_count.each(function (i, elem) {
                var _that = $(this);
                $(this).on("click", function () {
                    $(this).next().find("input[type=number]").val(1);
                    $(this).next().toggleClass("active");
                    return false;
                });
            });
        }
    }, 0);
    $b_remove.on("click", function () {
        //console.log($(this).parent().find('input').val());
        if($(this).parent().find('input').val() == 0){
            $(this).parent().removeClass("active");
        }
    });

    var $form_search_wrap = $(".form_search_wrap");

    if($form_search_wrap.height() > 60){
        $form_search_wrap.addClass("form_search_wrap_h");
    }


    $(window).on("resize", function () {});

    $(document).on("scroll", function () {
        NS.check_to_top();
    });

});